<template>
  <div v-if="!loading">
    <v-container
      v-if="detail !== null"
      style="padding: 0 0 20px 0; margin: auto; border-radius: 5px; background:white;"
    >
      <v-form
        ref="entryForm"
        @submit.prevent="submit()"
        lazy-validation
        :disabled="loading"
      >
        <v-card
          tile
          flat
          style="
          border-bottom: 2px solid rgba(0,0,0,0.1);
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          position:sticky;
          top:60px;
          background:white;
          z-index:5;
        "
        >
          <div
            style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
          >
            <v-toolbar-title class="overline" style="posiiton:relative;">
              <p
                :style="
                  `font-size:${wWidth > 780 ? '20px' : '3vw'}; margin: 0;`
                "
              >
                Detail Project
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      @click="getDetail"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </p>
            </v-toolbar-title>
          </div>
          <div class="actionButton">
            <div>
              <v-btn
                v-if="detail.status === 0"
                @click="submitProject"
                rounded
                elevation="0"
                color="primary"
                class="white--text font-weight-bold ma-2"
                style="font-size:12px;"
                :loading="loading"
              >
                Submit
              </v-btn>
            </div>
            <!-- Cancel button -->
            <!-- <div v-if="detail.employee.id === getUserProfile.employee.id">
              <v-dialog
                v-if="detail.status.id === 0"
                v-model="cancelDialog"
                width="500"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    type="button"
                    rounded
                    elevation="0"
                    color="red"
                    class="white--text font-weight-bold ma-2"
                    style="font-size:12px;"
                    :loading="loading"
                  >
                    Cancel
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Form Pembatalan
                  </v-card-title>

                  <v-card-text class="d-flex justify-center align-center">
                    <v-col
                      md="12"
                      style="padding: 0; position:relative; top:20px;"
                    >
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            class="text-left"
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Alasan pembatalan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-textarea v-model="form.cancel_reason" outlined />
                        </v-col>
                      </div>
                    </v-col>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      color="error"
                      text
                      outlined
                      @click="cancelDialog = false"
                      :loading="loading"
                    >
                      Tutup
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click.prevent="selfCancel()"
                      color="primary"
                      type="button"
                      text
                      outlined
                      :loading="loading"
                    >
                      Batalkan pengajuan
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div> -->

            <v-btn
              type="button"
              rounded
              elevation="0"
              color="black"
              class="black--text font-weight-bold ma-2"
              style="font-size:12px;"
              @click.prevent="close"
              :loading="loading"
              outlined
            >
              <v-icon small>mdi-chevron-left</v-icon>
              Kembali
            </v-btn>
          </div>
        </v-card>

        <v-card elevation="0">
          <v-toolbar-title
            class="overline"
            style="padding:0; text-align:center; height:30px;"
          >
            <!-- <p
              v-if="detail.request_no !== null"
              style="font-size: 20px; margin: 0; font-weight: bold; color:indigo;position:relative;"
            >
              {{ detail.request_no }}
            </p> -->

            <div style=" position:absolute; right:0; top:-20px;">
              <p style="font-size:10px; margin: 0;"></p>
              diajukan pada :
              {{ detail.created_at }}
            </div>

            <div style="position:absolute; right:10px; top:5px;">
              <v-btn
                v-if="detail.status === 0"
                @click.prevent="edit()"
                type="button"
                text
                icon
                elevation="0"
                color="primary"
                class="white--text font-weight-bold"
                style="font-size:12px;"
                :loading="loading"
              >
                <v-icon small>mdi-pencil</v-icon>
                Edit
              </v-btn>
            </div>
          </v-toolbar-title>
          <v-row style="padding: 15px" no-gutters>
            <v-col cols="12" md="4" style="padding: 0;">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Nama Project
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:6px;">
                  <v-text-field outlined dense :value="detail.name" readonly />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Kode Project
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:4px;">
                  <v-text-field
                    dense
                    :value="detail.odoo_project_code"
                    readonly
                    outlined
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0;">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Status
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:6.5px;">
                  <v-text-field
                    outlined
                    dense
                    :value="status(detail.status)"
                    readonly
                  />
                </v-col>
              </div>
            </v-col>

            <v-col cols="12" md="4" style="padding: 0;">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Penanggung Jawab
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:6px;">
                  <v-text-field
                    outlined
                    dense
                    :value="detail.employee_name"
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Perusahaan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:4px;">
                  <v-text-field
                    outlined
                    dense
                    :value="detail.company_name"
                    readonly
                  />
                </v-col>
              </div> </v-col
            ><v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Keterangan Tambahan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:4px;">
                  <v-textarea
                    auto-grow
                    outlined
                    dense
                    :value="detail.description"
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
      <div
        class="d-flex  flex-column justify-center align-center"
        style="padding: 0 25px;"
      >
        <router-link to="/e-catalogue/catalogue-list">
          <v-btn
            elevation="1"
            color="primary"
            type="submit"
            class="white--text font-weight-bold mb-5"
            style="font-size:14px; width:100%"
            :loading="loading"
          >
            BUAT FPB BARU
          </v-btn>
        </router-link>
      </div>
    </v-container>
    <div
      v-else
      class="d-flex justify-center align-center"
      style="width:100%; height:80vh;position:relative;"
    >
      <v-btn
        type="button"
        rounded
        elevation="0"
        color="red"
        class="white--text font-weight-bold ma-2"
        style="font-size:12px; position:absolute; top: 10px; left:10px;"
        @click.prevent="close"
        :loading="loading"
        outlined
      >
        <v-icon small>mdi-chevron-left</v-icon>
        Kembali
      </v-btn>
      <p style="color: RGBA(0,0,0,0.5); font-size:24px; font-weight:bold;">
        DATA NOT FOUND
      </p>
    </div>
  </div>
  <div v-else class="d-flex justify-center align-center" style="height:100%;">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'leavesubmission',
  components: {},
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      eSanqua: buildType.apiURL('esanqua'),
      proc: buildType.apiURL('proc'),
      build: process.env.VUE_APP_BUILD_TYPE,
      wWidth: window.innerWidth,
      dialog: false,
      cancelDialog: false,
      loading: false,
      detail: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  async mounted() {
    this.loading = true
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    await this.getDetail()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapMutations(['setProjectDetail']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    async getDetail() {
      this.loading = true
      const url = `${this.proc}project/detail/${this.$route.params.id}`
      await axios
        .get(url)
        .then(res => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            // res.data.data.
            this.detail = res.data.data
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          return (this.loading = false)
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    close() {
      if (this.detail !== null) {
        this.$refs.entryForm.reset()
      }
      if (this.prevRoute !== undefined) {
        if (this.prevRoute.path !== '/e-catalogue/project/submission') {
          this.$router.push(this.prevRoute.path)
        } else {
          this.$router.push('/e-catalogue/project')
        }
      } else {
        this.$router.push('/e-catalogue/project')
      }
    },
    async submitProject() {
      this.loading = true
      await axios
        .post(`${this.proc}project/submit`, {
          id: this.$route.params.id
        })
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    // async cancelProject() {
    //   this.loading = true
    //   await axios
    //     .post(`${this.hrsApi}employee/leave/approve_leader`, {
    //       id: this.$route.params.id,
    //       note: this.form.direct_leader_note
    //     })
    //     .then(res => {
    //       this.loading = false
    //       if (res.data.status_code !== '-99') {
    //         this.showMsgDialog('success', res.data.status_msg, false)
    //         this.getDetail()
    //         this.approveDialog = false
    //       } else {
    //         if (res.data.status_msg === 'Parameter value has problem') {
    //           this.showMsgDialog(
    //             'error',
    //             'Invalid input, Parameter value has problem',
    //             false
    //           )
    //         } else {
    //           this.showMsgDialog('error', res.data.status_msg, false)
    //         }
    //       }
    //       return null
    //     })
    //     .catch(err => {
    //       this.showMsgDialog(
    //         'error',
    //         err
    //           ? 'Something went wrong, Please contact an admin!'
    //           : 'Something went wrong, Please contact an admin!',
    //         false
    //       )
    //       this.loading = false
    //       return null
    //     })
    // },
    edit() {
      this.setProjectDetail(this.detail)
      setTimeout(() => {
        this.$router.push('/e-catalogue/project/submission')
      }, 300)
    },
    status(val) {
      switch (val) {
        case -1:
          return 'Cancel'
        case 0:
          return 'Draft'
        case 1:
          return 'Submited'
      }
    },
    statusColor(val) {
      switch (val) {
        case -1:
          return 'error'
        case 0:
          return 'primary'
        case 1:
          return 'success'
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss">
.actionButton {
  position: relative;
  display: flex;
  // width: 400px;
}
.approvalMatrix {
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
}
@media (max-width: 766px) {
  .actionButton {
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
  }
  .approvalMatrix {
    margin-bottom: 50px;
  }
}
</style>
